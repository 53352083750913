<template>
  <footer>
    <h1>SaurusRehv koduleht</h1>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
  display: flex;
  justify-content: center; /* horizontally center the items */
  align-items: center; /* vertically center the items (optional) */
}
h1 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: italic;
}
</style>
