<template>
  <div>
    <HeaderComp></HeaderComp>

    <div class="form-container">
      <h1>Küsi pakkumist!</h1>

      <form @submit.prevent="createPost">
        <table>
          <tbody>
            <tr>
              <td><label for="content">Email: </label></td>
              <td>
                <textarea
                  id="header"
                  name="header"
                  rows="2"
                  required
                  placeholder="Email"
                  v-model="header"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td><label for="content">Kirja sisu: </label></td>
              <td>
                <textarea
                  id="content"
                  name="content"
                  rows="5"
                  required
                  placeholder="Teie soov!"
                  v-model="content"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <button
          @click="createPost"
          :disabled="!filled_content || !filled_header || isSubmitting"
        >
          Saada
        </button> -->
      </form>
    </div>
  </div>
  <FooterComp></FooterComp>
</template>

<script>
import DropdownMenu from "@/components/DropdownMenu.vue";
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";

export default {
  name: "AddPostView",
  components: {
    DropdownMenu,
    HeaderComp,
    FooterComp,
  },
  data: function () {
    return {
      content: "",
      header: "",
      filled_content: false,
      filled_header: false,
      isSubmitting: false,
    };
  },
  watch: {
    content: function () {
      this.filled_content = this.content.length > 0;
    },
    header: function () {
      this.filled_header = this.header.length > 0;
    },
  },

  methods: {
    createPost() {
      if (this.isSubmitting) return; // Prevent duplicate submissions
      this.isSubmitting = true;
      fetch("http://localhost:3000/api/posts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          title: this.header,
          body: this.content,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.$router.push("/");
        })
        .catch((e) => {
          console.log(e);
          console.log("error");
        });
    },
  },
  mounted() {
    fetch(`http://localhost:3000/auth/authenticate`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.authenticated) {
          console.log("authenticated");
        } else {
          console.log("not authenticated");
          this.$router.push("/login");
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => {
        this.isSubmitting = false; // Reset the flag
      });
  },
};
</script>

<style>
@import "@/styles/addPost.css";
</style>
