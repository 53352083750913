<template>
  <div>
    <HeaderComp></HeaderComp>
    <div class="form-container">
      <h1>Meist</h1>
      <p>
        Saurusrehv OÜ asutaja Aivo Kade hakkas veoautorehvidega tegelema aastast
        1991. Soomes saanud väljaõppe veoauto rehvide taastamises (kolm aastat
        töökogemust) ja Saksamaal veoauto-, traktorite- ja tööstusrehvide
        remontimises. Igapäevaselt praktiseerides ja analüüsides klientidelt
        saadud infot rehvide käitumisest erinevates oludes on kogutud korralik
        pagas teadmisi kvaliteetseks teenindamiseks. Parim tunnustus tehtud
        töödele on pidev püsiklientide lisandumine. Meilt kõik rehvitööd,
        rehvide taastamise tellime oma koostööpartneritelt.
      </p>
    </div>
    <div class="form-container">
      <h1>Aadress: Selli tn 3, 50113 Tartu</h1>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4187.269134929371!2d26.73330521307064!3d58.34896628899664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eb37345e4baff3%3A0xb80556ade11633c2!2sSAURUSREHV%20O%C3%9C!5e0!3m2!1set!2sus!4v1730064825014!5m2!1set!2sus"
        width="570"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
  <FooterComp></FooterComp>
</template>

<script>
import DropdownMenu from "@/components/DropdownMenu.vue";
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";

export default {
  name: "ContactUs",
  components: {
    DropdownMenu,
    HeaderComp,
    FooterComp,
  },
};
</script>

<style></style>
