<template>
  <nav>
    <!-- Router gets us to pages - defined in router/index.js -->
    <router-link to="/">Kodu</router-link>
    <p>|</p>
    <router-link to="/add-post">Küsi pakkumist!</router-link>
    <p>|</p>
    <router-link to="/login">.</router-link>
    <p>|</p>
    <router-link to="/signup">.</router-link>
    <p>|</p>
    <router-link to="/contactus">Meist</router-link>
    <img
      class="logo"
      id="logo"
      src="@/assets/logo.png"
      height="50"
      alt="User icon"
    />

    <!-- The Dropdownmenu component -->
    <DropdownMenu />
  </nav>
</template>

<script>
import DropdownMenu from "@/components/DropdownMenu.vue";

export default {
  name: "Header",
  components: {
    DropdownMenu,
  },
};
</script>

<style></style>
